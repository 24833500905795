import React from 'react'
import { graphql, Link } from 'gatsby'

import Box from '../components/box'
import Layout from '../components/layout'
import SEO from '../components/seo'

const BlogNode = ({node}) => {
	const uri = `/b/${node.slug}`
	return (
		<Box
			time={node.createdAt}
			title={<Link to={uri}>{node.title}</Link>} >
			<p>{node.post.childMarkdownRemark.excerpt}</p>
		</Box>
	)
}


const BlogNavigation = ({pages}) => (
	<nav>
		{
			pages > 1 && Array.from({ length: pages }).map((_, n) => (<Link key={n} to={`/b/${n+1}`}>{n}</Link>))
		}
	</nav>
)


const PostList = ({data, pageContext}) => {
	if (!data || !data.allContentfulBlog) { return }

	const nodes = data.allContentfulBlog.edges.map(({node}) => (
		<BlogNode key={node.id} node={node} />
	))

	return (
		<Layout pageTitle="Yo.">
			<SEO title="Blog" />
			<>
				{nodes}
				<BlogNavigation pages={pageContext.pages} />
			</>
		</Layout>
	)
}

export default PostList


export const query = graphql`
	query($skip: Int!, $limit: Int!) {
		allContentfulBlog(
			sort: {fields:createdAt, order:DESC}
			limit: $limit
			skip: $skip
		) {
			totalCount
			edges {
				node {
					id
					slug
					title
					createdAt(formatString: "DD MMMM, YYYY")
					post {
						childMarkdownRemark {
							excerpt(pruneLength:280)
						}
					}
				}
			}
		}
	}
`
